<template>
  <div class="useLayout">
    <el-form ref="useForm" :inline="true" :model="formData" class="demo-form-inline" label-width="70px"
      v-if="searchList.length > 0">
      <el-form-item v-for="(item, index) in searchList" :key="index" :label="item.name"
        v-show="item.power.includes(account_type)">
        <!-- 输入框 -->
        <div class="text" v-if="item.type == 'text'">
          <el-input v-model.trim="formData[item.key]" :placeholder="item.placeholder" />
        </div>
        <!-- 时间选择器 -->
        <div class="dateTime" v-if="item.type == 'dateTime'">
          <el-date-picker v-model="formData[item.key]" type="daterange" unlink-panels range-separator="至"
            start-placeholder="开始时间" end-placeholder="结束时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
            placeholder="请选择日期" :shortcuts="shortcuts" :disabledDate="disabledDateFn" :clearable="account_type == 1 || account_type == 6" />
        </div>
        <!-- 下拉框 -->
        <div class="select" v-if="item.type == 'select'">
          <el-select v-model="formData[item.key]" filterable :clearable="item.clearable || true" placeholder="请选择">
            <el-option v-for="(ele, eleIndex) in item.data" :key="eleIndex" :label="item.codeLabel != undefined ? ele[item.codeLabel] : ele.label
              " :value="item.codeValue != undefined ? ele[item.codeValue] : ele.value
    " />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="primary" @click="getTableData('search')">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 新增等批量操作 -->
    <div class="button-list" v-if="props.buttonList.length > 0 && props.buttonVisible && MONEYPOWERID != 7">
      <el-button v-for="(item, index) in props.buttonList" :key="index" :type="item.buttonType" v-show="setShow(item)"
        @click="handleClick(item.type)">{{ item.title }}</el-button>
    </div>
    <!-- 表格 -->
    <el-table ref="useTable" id="useTable" :data="tableData" style="width: 100%" v-loading="loading"
      :max-height="maxHeight" :row-class-name="tableRowClassName" class="table">
      <el-table-column v-for="(item, index) in props.tableHeader" :key="index" :prop="item.prop" :label="item.label"
        :width="item.width" :fixed="item.fixed" align="center">
        <template #default="scope">
          <div v-if="item.hasOwnProperty('slot')">
            <slot :name="item.slot" :scope="scope"></slot>
          </div>
          <!-- 付款金额 -->
          <div class="config" v-if="item.prop == 'remit_money'">
            <span class="green-text bold-text">{{
              scope.row.remit_money
            }}</span>
          </div>
          <!-- 总金额 -->
          <div class="config" v-if="item.prop == 'money'">
            <span class="bold-text">{{ scope.row.money }}</span>
          </div>
          <!-- 欠款金额 -->
          <div class="config" v-if="item.prop == 'arrears_money'">
            <span class="orange-text bold-text">{{
              scope.row.arrears_money
            }}</span>
          </div>
          <!-- 退款金额 -->
          <div class="config" v-if="item.prop == 'back_money'">
            <span class="red-text bold-text">{{ scope.row.back_money }}</span>
          </div>
          <!-- 操作列 -->
          <div class="config" v-if="item.prop == 'config'">
            <slot name="config" :scope="scope"></slot>
          </div>
          <!-- 合同 -->
          <div class="config" v-if="item.prop == 'offline_agreement'">
            <slot name="offline_agreement" :scope="scope"></slot>
          </div>
          <!-- 账号等级 -->
          <div class="account_type" v-if="item.prop == 'account_type'">
            <slot name="account_type" :scope="scope"></slot>
          </div>
          <!-- 默认 -->
          <span v-if="!scopeArr.includes(item.prop)">{{
            scope.row[item.prop]
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="footer">
      <div class="countNum" v-if="MONEYISPOWER && props.is_refund">
        已退款金额：<span class="bold-text red-text">{{
          handlerMark(refund_money)
        }}</span>
        元
      </div>
      <div class="countNum" v-if="MONEYISPOWER && props.is_count">
        付款总金额：<span class="bold-text green-text">{{
          handlerMark(count_money)
        }}</span>
        元
      </div>
      <div class="countNum" v-if="MONEYISPOWER && props.is_arrears">
        欠款总金额：<span class="bold-text orange-text">{{
          handlerMark(debt_money)
        }}</span>
        元
      </div>
      <div class="countNum" v-if="MONEYPOWERID == 1 && props.isSale">
        今天加总V：<span class="bold-text orange-text">{{ countData.dt_v }}</span>，
        昨天加总V：<span class="bold-text orange-text">{{ countData.zt_v }}</span>，
        前天加总V：<span class="bold-text orange-text">{{ countData.qt_v }}</span>，
        大前天加总V：<span class="bold-text orange-text">{{ countData.dqt_v }}</span>
      </div>
      <el-pagination background :page-sizes="[10, 20, 30, 50, 100]" :default-page-size="defaultSize"
        @size-change="handleSizeChange" @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper" :total="total" />
    </div>
  </div>
</template>
<script setup>
import { shortcuts, disabledDateFn, monthTime } from "@/api/date-time";
// import { apiGet } from '@/plugins/axiosInstance';
import { MONEYPOWER, handlerMark } from "@/api/common";
import { apiPost, apiGet } from '@/plugins/axiosInstance';
import {
  defineProps,
  defineEmits,
  defineExpose,
  onMounted,
  ref,
  reactive,
  nextTick,
} from "vue";
const props = defineProps({
  tableHeader: {
    default: [],
  },
  defaultSize: {
    default: 10
  },
  tableUrl: {
    default: "",
  },
  extraFormData: {
    default: {}
  },
  searchList: {
    default: [],
  },
  debt_money: {
    default: 0,
  },
  refund_money: {
    default: 0,
  },
  buttonList: {
    default: [],
  },
  buttonVisible: {
    default: true,
  },
  is_count: {
    default: false,
  },
  is_refund: {
    default: false,
  },
  is_arrears: {
    default: false,
  },
  isSale: {
    default: false
  }
});
const MONEYPOWERID = sessionStorage.getItem("account_type");
const MONEYISPOWER = MONEYPOWER.includes(MONEYPOWERID);
const emits = defineEmits(["handleClick"]);

const scopeArr = reactive([
  "config",
  "account_type",
  "back_money",
  "remit_money",
  "arrears_money",
  "money",
  "offline_agreement"
]);

const account_type = ref(sessionStorage.getItem("account_type"));

let loading = ref(true);
const handleSizeChange = (index) => {
  size.value = index;
  getTableData();

};

const handleCurrentChange = (index) => {
  page.value = index;
  getTableData();
};
// const formData = computed(() => {
//     let data = {}
//     props.searchList.forEach(ele => {
//         data[ele.key] = '';
//     })
//     return data
// });
const formData = reactive({});

const setShow = (item) => {
  if (item.type == 'export') {
    if (MONEYPOWERID == 1|| MONEYPOWERID == 61) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}
// const search = async () => {
//   await emits("search", formData)
//   loading.value = false;
// };

const handleClick = (type) => {
  emits("handleClick", type);
};


const initFormData = () => {
  let obj = {};
  props.searchList.forEach(ele => {
    if (ele.type == 'text' || ele.type == 'select') {
      obj[ele.key] = "";
    } else if (ele.type == 'dateTime') {

      if (ele.default == 'month') {
        obj[ele.key] = monthTime();
      }
    }
  })
  return Object.assign(formData, obj);
}
// 获取数据
let page = ref(1);
let size = ref(10);
let total = ref(0);
let tableData = reactive([]);
let count_money = ref(0);
let refund_money = ref(0);
let debt_money = ref(0);
let countData = ref({});
const getTableData = async () => {
  // if (type == 'search') {
  //   page.value = '1';
  // }
  if (formData.date_time != undefined && formData.date_time.length > 0) {
    formData.startTime = formData.date_time[0];
    formData.endTime = formData.date_time[1];
  } else {
    formData.startTime = "";
    formData.endTime = "";
    // formData.datetime = "2024-02-01,2024-02-29"
  }

  loading.value = true;

  await apiPost(props.tableUrl, {
    ...formData,
    ...props.extraFormData,
    page: page.value,
    limit: size.value || 10
  }).then(res => {
    loading.value = false;
    tableData.length = 0;
    count_money.value = res.data.count_money;
    refund_money.value = res.data.refund_money;
    debt_money.value = res.data.debt_money;
    Object.assign(tableData, res.data.list);
    page.value = res.data.page;
    size.value = res.data.limit;
    total.value = res.data.total;
    countData.value = {
      dt_v: res.data.dt_v,
      zt_v: res.data.zt_v,
      qt_v: res.data.qt_v,
      dqt_v: res.data.dqt_v,
    }
  })
}

let maxHeight = ref("640")



onMounted(async () => {
  initFormData();
  await selectDataList();
  await getTableData();
  nextTick(() => {
    // let aa = useTable.value
    let useTable = document.getElementById('useTable');
    maxHeight.value = window.innerHeight - useTable.offsetTop - 70;
  })
});
defineExpose({
  formData,
  getTableData,
  total,
});



// 颜色
const tableRowClassName = ({ row }) => {
  if (row.whether == '1') {
    return 'success-row'
  }
}

// 
const selectDataList = async () => {
  let arr = props.searchList.filter((ele) => ele.type == 'select')
  arr.forEach(async ele => {
    if (ele.value != undefined) {
      formData[ele.key] = ele.value;
    }
    if (ele.url != undefined) {
      await apiGet(ele.url, ele.urlData).then(res => {
        if (ele.screen != undefined && ele.screen) {
          ele.data.length = 1;
          ele.data.push(...res.data.filter(item => !item.label.includes('离职') && !item.label.includes('作废') && !item.label.includes('销售账号')));
        } else {
          ele.data = res.data;
        }
      })
    }

  })
}
onMounted(() => {
  console.log('执行')
  size.value = props.defaultSize;
})
</script>
  
<style lang="scss" scoped>
.footer {
  margin: 20px;

  .el-pagination {
    float: right;
  }

  .countNum {
    float: left;
  }
}

.button-list {
  margin: 0px 20px;
  margin-bottom: 10px;
}

.search-button {
  margin-left: 20px;
  // float: right;
  // margin-right: 30px;
}

.table ::v-deep(.el-scrollbar__bar, .is-horizontal) {
  height: 12px !important;
}

::v-deep(.el-table .success-row) {
  --el-table-tr-bg-color: #ecf4ff;
}

::v-deep(.el-scrollbar__bar) {
  display: block !important;
}

::v-deep(.el-scrollbar__thumb) {
  opacity: 0.6;
}

@media (max-width: 767px) {
  .demo-form-inline {
    height: 10rem;
    overflow-y: scroll;
  }
}
</style>