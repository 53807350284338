
import { ElMessage } from "element-plus";
import { apiPostMessage } from '@/plugins/axiosInstance';
import { useRouter } from "vue-router";

const router = useRouter();

// 返回登录页
export const goBackLogin = () => {
    sessionStorage.clear();
    let time = setTimeout(() => {
        window.location.reload();
        clearTimeout(time);
    }, 1000)
}

// ip校验
const checkoutIP = (rule, value, callback) => {
    const ipRules = /^((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9]))$/
    if (!ipRules.test(value)) {
        callback(new Error('ip不合法'))
    } else {
        callback()
    }
};
// 公共校验
// pattern: /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/, }]
export const useRules = {
    number: [{ required: true, message: '请输入推广编号', trigger: 'blur' }],
    add_wx_time: [{ required: true, message: '请输入学员姓名', trigger: 'blur' }],
    discussant: [{ required: true, message: '请输入学员姓名', trigger: 'blur' }],
    school: [{ required: true, message: '请输入学员姓名', trigger: 'blur' }],
    name: [{ required: true, message: '请输入学员姓名', trigger: 'blur' }],
    money: [{ required: true, message: '请输入总金额', trigger: 'blur' }],
    real_name: [{ required: true, message: '请输入使用者真实姓名', trigger: 'blur' }],
    subjects_name: [{ required: true, message: '请输入学习课程', trigger: 'blur' }],
    address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
    express_num: [{ required: true, message: '请输入快递单号', trigger: 'blur' }],
    express_company: [{ required: true, message: '请输入快递公司', trigger: 'blur' }],
    sex: [{ required: true, message: '请选择学员性别', trigger: 'change' }],
    phone: [{ required: true, message: '请输入正确的学员手机号', trigger: 'blur' }],
    abutment_id: [{ required: true, message: '请选择班主任', trigger: 'blur' }],
    swt_ip: [{ required: true, message: '请输入正确的商务通ip', trigger: 'blur' }, { validator: checkoutIP, trigger: 'blur' }],
    identity: [{ required: true, message: '请输入客人的永久身份', trigger: 'blur' }],
    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
    account_name: [{ required: true, message: '请输入用账号', trigger: 'blur' }],
    account_type: [{ required: true, message: '请选择账号等级', trigger: 'blur' }],
    user_name: [{ required: true, message: '请选择账号等级', trigger: 'blur' }],
    weixin: [{required: true, message: '请选择学员微信'}],
    class_type: [{required: true, message: '请选择选择班型'}],
    repair_time: [{required: true, message: '请选择学员微信', trigger: 'blur'}],
    old_password: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
};
// 公共提交方法
export const useSubmit = (url, formData, ruleForm, routeName) => {
    ruleForm.validate(valid => {
        if (valid) {
            apiPostMessage(url, formData).then(() => {
                router.push({
                    name: routeName
                })
            })
        } else {
            ElMessage({
                message: '请填写必填项',
                type: 'warning'
            })
        }
    })
};
// 公共重置方法
export const useReset = (formData) => {
    for (let index in formData) {
        if (typeof (formData[index]) == 'string') {
            formData[index] = '';
        } else if (typeof (formData[index]) == 'number') {
            formData[index] = 0;
        }
        else {
            formData[index].length = 0;
        }
    }
};

// 编辑权限
export const EXITPOWER = ["1"];
// 总金额展示权限
export const MONEYPOWER = ["1", "3", "5", "6", "7"];
// 总金额展示权限
export const OPENPOWER = ["1", "2"];



// 下载文件
export const downLoadFile = (file) => {
    var a = document.createElement('a');
    // var blob = new Blob(['https://img.xunpanbao.top/123.docx'],{type:'application/vnd.ms-excel'})
    // a.href = URL.createObjectURL(blob);
    // a.download = file.name;
    a.setAttribute('href', file.url);
    a.setAttribute('target', '_blank');
    a.click();
    a.remove();
} 

// 公共千分符方法
export const handlerMark = (item) => {
    return item.toLocaleString();
}

// 公共baseURL
export const baseURL =  process.env.NODE_ENV == 'development' ? '/api' : '/public/index.php/admin'

export const uploadURL = baseURL + '/add/FtpImages'

export const setPhone = (item) => {
    let newData = item.slice(4, 8)
    return item.replace(newData, '****')
}

// copy复制
export const useCopyText = (text) => {
    let oInput = document.createElement('input');
    oInput.value = text;
    document.body.appendChild(oInput);
    oInput.select();
    navigator.clipboard.writeText(text).then(() => {
        ElMessage.success('复制成功：' + text)
        document.body.removeChild(oInput)
    })
}